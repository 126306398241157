import { InputContainer } from "./input-container";
import { CustomInput } from "./custom-input";

export const TextInput: React.FC<{
  title: string;
  fieldName: string;
  maxLength: number;
  inputType?: string;
}> = ({ title, fieldName, maxLength, inputType = "input" }) => {
  return (
    <InputContainer title={title}>
      <div className="text-input">
        <div className="input-container__input-title">{`Max count ${maxLength}`}</div>
        <CustomInput
          fieldName={fieldName}
          required
          maxLength={maxLength}
          inputType={inputType}
        />
      </div>
    </InputContainer>
  );
};
