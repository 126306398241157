import { Button, Grid } from "@mui/material";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";

export enum CompanyServiceType {
  RESIDENTIAL = "RESIDENTIAL",
  COMMERCIAL_AND_DEVELOPMENT = "COMMERCIAL_AND_DEVELOPMENT",
  MEDICAL = "MEDICAL",
  BUSINESS = "BUSINESS",
  PROPERTY_ADVISORY = "PROPERTY_ADVISORY",
  PRIVATE_FUND = "PRIVATE_FUND",
  ASSET_FINANCE = "ASSET_FINANCE",
  CAR_FINANCE = "CAR_FINANCE",
  PERSONAL_LOANS = "PERSONAL_LOANS",
}

export const SERVICE_DETAILS_LIST: Array<{
  id: CompanyServiceType;
  label: string;
}> = [
  {
    id: CompanyServiceType.RESIDENTIAL,
    label: "Residential",
  },
  {
    id: CompanyServiceType.COMMERCIAL_AND_DEVELOPMENT,
    label: "Commercial & Development",
  },
  {
    id: CompanyServiceType.MEDICAL,
    label: "Medical",
  },
  {
    id: CompanyServiceType.BUSINESS,
    label: "Business",
  },
  {
    id: CompanyServiceType.PROPERTY_ADVISORY,
    label: "Property Advisory",
  },
  {
    id: CompanyServiceType.PRIVATE_FUND,
    label: "Private Fund",
  },
  {
    id: CompanyServiceType.ASSET_FINANCE,
    label: "Asset Finance",
  },
  {
    id: CompanyServiceType.CAR_FINANCE,
    label: "Car Finance",
  },
  {
    id: CompanyServiceType.PERSONAL_LOANS,
    label: "Personal Loans",
  },
];

export type ServiceSelectionProps = {
  value: Array<CompanyServiceType>;
  services?: typeof SERVICE_DETAILS_LIST;
  onChange: (services: Array<CompanyServiceType>) => void;
  minAmount?: number;
  maxAmount?: number;
};

export const ServiceSelection: React.FC<ServiceSelectionProps> = ({
  services = SERVICE_DETAILS_LIST,
  onChange,
  minAmount = 4,
  maxAmount = 6,
  value,
}) => {
  const handleServiceSelection = useCallback(
    (serviceId: CompanyServiceType) => {
      if (value.includes(serviceId)) {
        onChange(value.filter((id) => id !== serviceId));
      } else {
        onChange([...value, serviceId]);
      }
    },
    [value, onChange]
  );

  return (
    <div className="service-selection">
      <div className="input-container__title">{`Select ${minAmount} - ${maxAmount} categories`}</div>
      <Grid
        className="service-selection__grid"
        container
        columnSpacing={{ xs: 0.5, md: 1 }}
        rowSpacing={{ xs: 1, md: 2 }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {services.map((service) => (
          <Grid key={`service-item-grid-${service.id}`} item xs={6} md={4}>
            <Button
              key={`service-item-${service.id}`}
              onClick={() => handleServiceSelection(service.id)}
              className={classNames("service-selection-item", {
                selected: value.includes(service.id),
              })}
            >
              {service.label}
            </Button>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
