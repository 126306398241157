import { CustomInput } from "./custom-input";

export const CustomRateInput: React.FC<{
  title: string;
  fieldName: string;
  max: number;
  inputType: string;
}> = ({ title, fieldName, max, inputType }) => {
  return (
    <div className="rate-input__container">
      <div className="input-container__input-title">{title}</div>
      <CustomInput
        fieldName={fieldName}
        required
        max={max}
        inputType={inputType}
      />
    </div>
  );
};
