export const CustomSelect = ({ inputProps, title, value }) => {
  return (
    <div className="contact-method__container">
      <div className="contact-method__input">
        <div className="input-container__input-title">{title}</div>
        <input {...inputProps} type="radio" value={value} />
      </div>
    </div>
  );
};
