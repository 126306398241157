import { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { TextInput } from "./text-input";

export enum CompanyProcessType {
  DISCOVER = "DISCOVER",
  ANALYSE = "ANALYSE",
  ADVISE = "ADVISE",
  IMPLEMENT_AND_REVIEW = "IMPLEMENT_AND_REVIEW",
}

export const PROCESS_DETAILS_LIST: Array<{
  title: string;
  type: CompanyProcessType;
  defaultValue: string;
  order: number;
}> = [
  {
    title: "Discover",
    type: CompanyProcessType.DISCOVER,
    defaultValue: "",
    order: 1,
  },
  {
    title: "Analyse",
    type: CompanyProcessType.ANALYSE,
    defaultValue: "",
    order: 2,
  },
  {
    title: "Advise",
    type: CompanyProcessType.ADVISE,
    defaultValue: "",
    order: 3,
  },
  {
    title: "Implement & Review",
    type: CompanyProcessType.IMPLEMENT_AND_REVIEW,
    defaultValue: "",
    order: 4,
  },
];

export type ProcessInputListProps = {
  fieldName: string;
  processes: typeof PROCESS_DETAILS_LIST;
};

export const ProcessInputList: React.FC<ProcessInputListProps> = ({
  fieldName,
  processes,
}) => {
  const { control } = useFormContext();
  const { fields, prepend, update } = useFieldArray({
    control,
    name: fieldName,
  });
  useEffect(() => {
    if (fields.length === 0) {
      prepend(
        processes.map((process) => {
          return {
            title: process.title,
            description: process.defaultValue,
            type: process.type,
            order: process.order,
          };
        })
      );
    } else {
      fields.forEach((item, index) => {
        const process = processes.find(
          (process) => process.type === item["type"]
        );
        if (process) {
          update(index, {
            ...item,
            order: process.order,
            title: process.title,
          });
        }
      });
    }
  }, []);

  return (
    <div className="process-input-list">
      {fields.map((_, index) => (
        <div key={`process-input-container-${index}`}>
          <TextInput
            inputType="textarea"
            key={`process-input-${index}`}
            fieldName={`${fieldName}.${index}.description`}
            maxLength={120}
            title={`Our Process ${index + 1}: ${processes[index].title}`}
          />
        </div>
      ))}
    </div>
  );
};
