import { DeleteForever } from "@mui/icons-material";
import React from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import { CustomOutlineButton } from "../../../components/custom-button";
import {
  useCompanyHomePageDetails,
  useCompanyInfo,
  useCreateHomePageDetails,
  useUpdateHomePageDetails,
} from "../../../hooks/company.hooks";
import UploadImageField from "./components/upload-image-field";
import { InputContainer } from "./components/input-container.tsx";
import { CustomInput } from "./components/custom-input";
import { CustomSelect } from "./components/custom-select";
import { CustomRateInput } from "./components/custom-rate-input.tsx";
import { TextInput } from "./components/text-input.tsx";
import { ServiceSelectionController } from "./components/service-selection-controller.tsx";
import {
  PROCESS_DETAILS_LIST,
  ProcessInputList,
} from "./components/process-input-list.tsx";
import "./home-page.scss";
import { SERVICE_DETAILS_LIST } from "./components/service-selection.tsx";

const AboutUsText = ({ description, setValue, maxLength, fieldName }) => {
  return (
    <InputContainer title="About us text">
      <div className="input-container__input-title">{description}</div>
      <CustomInput
        maxLength={maxLength}
        fieldName={fieldName}
        setValue={setValue}
        required
        inputType="textarea"
      />
    </InputContainer>
  );
};
const ContactMethodInput = () => {
  const { watch, register } = useFormContext();
  const inputProps = { ...register("contactMethod") };
  const watchMethod = watch("contactMethod");
  return (
    <InputContainer title="Contact Method on Home Page">
      <div className="contact-method">
        <CustomSelect
          inputProps={inputProps}
          title="Email me contacts"
          value="email"
        />
        <div className="contact-method__container">
          <CustomSelect
            inputProps={inputProps}
            title="Calendly link"
            value="calendly"
          />
          <div className="input-container__input-title">Calendly link</div>
          <CustomInput
            disabled={watchMethod !== "calendly"}
            fieldName="calendlyURL"
            required={watchMethod === "calendly"}
            pattern={/^https:\/\/calendly\.com.*/i}
          />
        </div>
      </div>
    </InputContainer>
  );
};
const UploadImageInput = ({ title, name }) => {
  const {
    watch,
    control,
    formState: { errors },
  } = useFormContext();
  const imageURL = watch(name);

  return (
    <InputContainer title={title}>
      <UploadImageField
        name={name}
        control={control}
        imageURL={imageURL}
        errors={errors}
      />
    </InputContainer>
  );
};
const RateInput = () => {
  const RateInputElement = [
    {
      title: "Number of lenders",
      fieldName: "rate.numberOfLenders",
      inputType: "number",
    },
    {
      title: "Lowest Rate",
      fieldName: "rate.lowestRate",
      max: 100,
      inputType: "number",
    },
    {
      title: "Comparison Rate",
      fieldName: "rate.comparisonRate",
      max: 100,
      inputType: "number",
    },
  ];
  return (
    <InputContainer title="Interest rate Offering">
      <div className="rate-input">
        {RateInputElement.map((rateElement) => (
          <CustomRateInput
            key={rateElement.title}
            fieldName={rateElement.fieldName}
            title={rateElement.title}
            max={rateElement?.max}
            inputType={rateElement.inputType}
          />
        ))}
      </div>
    </InputContainer>
  );
};
const ClientReviewInput = () => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "reviews",
  });
  const ClientReviewInputElement = [
    { title: "Client Name", fieldName: "clientName" },
    { title: "Client Scenario", fieldName: "clientScenario" },
    {
      title: "Testimonial (Max 200 characters)",
      fieldName: "testimonial",
      maxLength: 200,
      inputType: "textarea",
    },
  ];
  return (
    <div className="client-review-inputs">
      {fields.map((item, index, arr) => (
        <div className="client-review-input" key={item.id}>
          <InputContainer title={`Client review ${index + 1}`}>
            <div className="client-review">
              {ClientReviewInputElement.map((reviewInputElement) => (
                <div
                  key={reviewInputElement.title}
                  className={`client-review__container ${reviewInputElement.fieldName}`}
                >
                  <div className="input-container__input-title">
                    {reviewInputElement.title}
                  </div>
                  <CustomInput
                    fieldName={`reviews.${index}.${reviewInputElement.fieldName}`}
                    required
                    inputType={reviewInputElement.inputType}
                    maxLength={reviewInputElement?.maxLength}
                  />
                </div>
              ))}
            </div>
          </InputContainer>
          {arr.length > 3 && (
            <div className="client-review-input__remove-review-button">
              <button type="button" onClick={() => remove(index)}>
                <DeleteForever />
              </button>
            </div>
          )}
        </div>
      ))}
      <div className="add-more-review">
        <CustomOutlineButton
          buttonType="secondary"
          label="Add more review"
          onClick={() => append()}
        />
      </div>
    </div>
  );
};

const HomePageSegment = () => {
  const companyHomePageDetails = useCompanyHomePageDetails();
  const methods = useForm({
    defaultValues: {
      reviews: [{}, {}, {}, {}],
      contactMethod: "email",
      featureImage: "",
      aboutUsImage: "",
      calendlyURL: "",
      serviceTitle: "Home & Asset Finance",
      services: [],
      processes: [],
    },
    values: companyHomePageDetails,
  });
  const {
    data: { id: companyId, homepageDetailsId },
  } = useCompanyInfo();

  const createHomePageDetails = useCreateHomePageDetails();
  const updateHomePageDetails = useUpdateHomePageDetails();

  const onSubmit = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (
        key.includes("rate") ||
        key.includes("reviews") ||
        key.includes("processes") ||
        key.includes("services")
      ) {
        formData.append(key, JSON.stringify(data[key]));
      } else {
        formData.append(key, data[key]);
      }
    });
    if (homepageDetailsId) {
      updateHomePageDetails.mutate({ id: companyId, params: formData });
    } else {
      createHomePageDetails.mutate({ id: companyId, params: formData });
    }
  };
  return (
    <FormProvider {...methods}>
      <div className="company-homepage">
        <form className="company-homepage__form">
          <TextInput title="Title Text" fieldName="titleText" maxLength={50} />
          <TextInput title="Sub Text" fieldName="subText" maxLength={80} />
          <ContactMethodInput />
          <UploadImageInput
            title="Feature Image (Top of Page)"
            name="featureImage"
          />
          <RateInput />
          <ClientReviewInput />
          <TextInput
            title="Our Services"
            fieldName="serviceTitle"
            maxLength={50}
          />
          <ServiceSelectionController
            services={SERVICE_DETAILS_LIST}
            fieldName="services"
          />
          <ProcessInputList
            fieldName="processes"
            processes={PROCESS_DETAILS_LIST}
          />
          <UploadImageInput
            title="About us Image (Bottom of Page)"
            name="aboutUsImage"
          />
          <AboutUsText
            description="Max count 500"
            maxLength={500}
            fieldName="aboutUsText"
          />
          <CustomOutlineButton
            className="homepage-submit-button"
            label="Save"
            buttonType="secondary"
            onClick={methods.handleSubmit(onSubmit)}
          />
        </form>
      </div>
    </FormProvider>
  );
};

export default HomePageSegment;
