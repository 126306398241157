import { Controller, useFormContext } from "react-hook-form";
import {
  SERVICE_DETAILS_LIST,
  ServiceSelection,
  ServiceSelectionProps,
} from "./service-selection";
import { ErrorMessage } from "@hookform/error-message";
import { useState } from "react";

export const ServiceSelectionController: React.FC<
  Omit<ServiceSelectionProps, "onChange"> & {
    fieldName: string;
  }
> = ({ services, minAmount = 4, maxAmount = 6, fieldName }) => {
  const {
    formState: { errors },
    control,
    trigger,
    watch,
  } = useFormContext();
  const [touched, setTouched] = useState(false);

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{
        validate: (value) => {
          if (value.length < minAmount || value.length > maxAmount) {
            return `Please select between ${minAmount} and ${maxAmount} services`;
          }
          return true;
        },
      }}
      render={({ field }) => {
        return (
          <div className="input-container">
            <input hidden {...field} />
            <ServiceSelection
              services={services}
              value={watch(fieldName) || []}
              onChange={(value) => {
                field.onChange(value);
                trigger(fieldName);
                if (!touched) {
                  setTouched(true);
                }
              }}
            />
            {touched && (
              <ErrorMessage
                className="error-message"
                errors={errors}
                name={fieldName}
                as={<p />}
              />
            )}
          </div>
        );
      }}
    />
  );
};
