import { ErrorMessage } from "@hookform/error-message";
import ContentEditable from "react-contenteditable";
import { useFormContext } from "react-hook-form";

export const CustomInput = ({
  inputType = "input",
  fieldName,
  required = false,
  max,
  maxLength,
  disabled,
  pattern,
}) => {
  const {
    register,
    formState: { errors },
    clearErrors,
    setValue,
    watch,
  } = useFormContext();
  return (
    <>
      {inputType !== "textarea" ? (
        <>
          <span className="input-holder" type={inputType} name={fieldName}>
            <input
              type={inputType}
              readOnly={disabled}
              {...register(fieldName, {
                required: required && "This field is required.",
                max: {
                  value: max,
                  message: `Max value is ${max}`,
                },
                maxLength: {
                  value: maxLength,
                  message: `Maximum character is ${maxLength}.`,
                },
                valueAsNumber: inputType === "number",
                pattern: {
                  value: pattern,
                  message: "Please enter a valid Calendly link",
                },
              })}
            />
          </span>
        </>
      ) : (
        <>
          <ContentEditable
            {...register(fieldName, {
              required: required && "This field is required.",
              maxLength: {
                value: maxLength,
                message: `Maximum character is ${maxLength}.`,
              },
            })}
            html={watch(fieldName) || ""}
            className="textarea"
            tagName="span"
            onChange={(e) => {
              setValue(fieldName, e.currentTarget.textContent, {
                shouldValidate: true,
                shouldDirty: true,
              });
              clearErrors(fieldName);
            }}
          />
        </>
      )}

      <ErrorMessage
        className="error-message"
        name={fieldName}
        errors={errors}
        as={<p />}
      />
    </>
  );
};
