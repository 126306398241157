import React from "react";
import AccountItemContainer from "./acccount-item-container";
import AccountItemBody from "./account-item-body";
import AccountItemHeader from "./account-item-header";
import EditableTextField from "./editable-text-field";

const CompanyConnective = ({ companyInfo, companyConnectiveDetails }) => {
  const companyInfoWithConnectiveDetails = {
    ...companyInfo,
    connectiveAccountId: companyConnectiveDetails?.accountId,
    connectivePartnerId: companyConnectiveDetails?.partnerId,
    connectiveApiKey: companyConnectiveDetails?.apiKey,
    connectiveApiToken: companyConnectiveDetails?.apiToken,
  };
  return (
    <AccountItemContainer>
      <AccountItemHeader title="Connective Setting" />
      <AccountItemBody
        title="CA Number"
        element={
          <EditableTextField
            value={companyInfoWithConnectiveDetails}
            name="connectiveAccountId"
          />
        }
      />
      <AccountItemBody
        title="Company Id"
        element={
          <EditableTextField
            value={companyInfoWithConnectiveDetails}
            name="connectivePartnerId"
          />
        }
      />
      <AccountItemBody
        title="API Key"
        element={
          <EditableTextField
            value={companyInfoWithConnectiveDetails}
            name="connectiveApiKey"
          />
        }
      />
      <AccountItemBody
        title="API Token"
        element={
          <EditableTextField
            value={companyInfoWithConnectiveDetails}
            name="connectiveApiToken"
          />
        }
      />
    </AccountItemContainer>
  );
};

export default CompanyConnective;
