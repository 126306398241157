import { ReactNode } from "react";

export const InputContainer: React.FC<{
  title: string;
  children: ReactNode;
}> = ({ title, children }) => {
  return (
    <div className="input-container">
      <div className="input-container__title">{title}</div>
      <div className="input-container__container">{children}</div>
    </div>
  );
};
